<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
          <b-overlay
    :show="loading"
     

    >
      <div class="m-2">
        <!-- Tabla Superior -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- Boton Actualizar Fiscalias @click="actualizarLista()" -->
            <b-button
              variant="secondary"
              class="btn-icon mr-2"
              @click="getAllFiscalias"
            >
              <feather-icon icon="RotateCcwIcon" />
            </b-button>
            <!-- fin Boton Sincronizar -->
            <!-- Boton Agregar Nueva Fiscalia -->
            <b-button
              variant="primary"
              class="mr-1"
              @click="confirmarSincronizar"
            >
              <feather-icon icon="RepeatIcon" class="mr-50" />

              <span class="text-middle">Sincronizar</span>
            </b-button>
            <!-- fin Boton Sincronizar -->
          </b-col>

          <!-- Buscar -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
            </div>
          </b-col>
          <!-- fin Buscar -->
        </b-row>
      </div>
          </b-overlay>

      <b-table
        ref="refListTable"
        selectable
        select-mode="single"
        hover
        :small="true"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        @row-selected="onRowSelected"
        show-empty
        empty-text="No se encontraron registros coincidentes"
        :filter="searchQuery"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <i class="feather icon-disc primary" />
          </template>

          <template v-else>
            <i class="feather icon-circle" />
          </template>
        </template>

        <!-- Column: Fiscal -->
        <template #cell(foto_fiscal)="data">
          <b-avatar :src="data.value" />
        </template>

        <!-- Column: Actions -->

        <template #cell(accion)="data">
          <div class="text-nowrap">
            <!-- <b-button
                    :to="{ name: 'fiscalia-ver', params: { id: data.item.id } }"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-secondary"
                    v-b-tooltip.hover.bottom="'Ver'"
                    class="btn-icon mr-1"
                    size="sm"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button> -->
            <b-button
              :to="{ name: 'fiscalia-editar', params: { id: data.item.id } }"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              v-b-tooltip.hover.bottom="'Editar'"
              class="btn-icon mr-1"
              size="sm"
            >
              <feather-icon icon="EditIcon" />
            </b-button>

            <b-button
              @click="confirmarEliminar(data.item)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.bottom="'Eliminar'"
              class="btn-icon"
              size="sm"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </template>
        <template #cell()="data">
          <span class="text-nowrap">{{ data.value }}</span>
        </template>

        <!-- <template #cell(accion)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>
                        <b-dropdown-item :to="{ name: 'fiscalia-ver', params: { id: data.item.id } }">
                            <feather-icon icon="FileTextIcon" />
                            <span class="align-middle ml-50">Ver</span>
                        </b-dropdown-item>

                        <b-dropdown-item :to="{ name: 'fiscalia-editar', params: { id: data.item.id } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Editar</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="confirmarEliminar(data.item)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Eliminar</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template> -->
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from "@axios";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BPagination,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,

    vSelect,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      loading:false,
      ispadre: false,
      searchQuery: null,
      selected: [],
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",

      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      tableColumns: [
        {
          key: "foto_fiscal",
          label: "fiscal",
        },
        { key: "nombre_fiscal", label: "Nombre Fiscal", sortable: false },
        { key: "nombre", label: "Fiscalia", sortable: false },
        { key: "direccion", label: "Direccion", sortable: false },
        { key: "telefono", label: "Telefono", sortable: false },
        // { key: 'fax', label: 'Fax', sortable: false },
        { key: "ubicacion", label: "Ubicacion", sortable: false },
        "accion",
      ],
      items: [],
    };
  },

  created() {
    this.getAllFiscalias();
  },

  methods: {
    verificarHijos(id) {
      //let rpt = true;
      axios
        .get(`/fiscalias/isPadre/${id}`)
        .then((response) => {
          this.ispadre = response.data.data;
          //return rpt;
          // this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.ispadre = false;
        });
    },

    confirmarSincronizar() {
     
      this.$swal({
        title: "Esta seguro de Sincronizar las Fiscalias ?",
        html: `<p>¡No podrás revertir esto!</p>`,
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sincronizar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.sincronizar().then((response) => {
            this.getAllFiscalias();
            if (response)
              this.$swal({
                icon: "success",
                title: "Actualizado!",
                timer: 2000,
                text: "Las Fiscalias Fueron Actualizadas.",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.loading=false;
          });
          
        }
      });
      
    },
    confirmarEliminar(item) {
      this.verificarHijos(item.id);
      //console.log(this.ispadre);
      var texto = "";
      if (this.ispadre) texto = "tiene registros que dependenden de el";

      this.$swal({
        title: "Esta seguro de eliminar ?",
        html: `<strong>${item.nombre}</strong>
                <strong>${texto}</strong>
                    <p>¡No podrás revertir esto!</p>
                    `,
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.eliminarItem(item.id).then((response) => {
            this.getAllFiscalias();
            if (response)
              this.$swal({
                icon: "success",
                title: "Eliminado!",
                timer: 2000,
                text: "Tu archivo ha sido eliminado.",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
          });
          
        }
      });
    },
    async sincronizar() {
      let rpt = true;
       this.loading=true;
      await axios.get(`/sincronizar/fiscalias`).catch((error) => {
        console.error(error);
        rpt = false;
      });
      return rpt;
    },

    async eliminarItem(id) {
      let rpt = true;
      await axios.delete(`/fiscalias/${id}`).catch((error) => {
        console.error(error);
        rpt = false;
      });
      return rpt;
    },

    onRowSelected(items) {
      this.selected = items;
    },
    getAllFiscalias() {
      axios
        .get(`/fiscalias`)
        .then((response) => {
          this.items = response.data.data;
          this.totalRows = this.items.length;
          // this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
